import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import ipo1 from "../../../static/img/ipo/ipo1.jpg"
import ipo2 from "../../../static/img/ipo/ipo2.jpg"
import ipo3 from "../../../static/img/ipo/ipo3.jpg"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="IPO Program"/>
            <Banner title="IPO Program"/>
            <section className="about_consulting_area bg-white pt-5 pb-5">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-7 order-1 order-lg-0">
                            <div className="ex_content">
                                <h3>Client’s profile</h3>

                                <p>A financial company has executed a number of debt
                                    placements (local
                                    notes and Eurobonds), and is willing to take a step further on its fundraising
                                    strategy
                                    through an IPO,
                                    with the aim of strengthening its capital structure and enhance its liquidity and
                                    indebtedness ratios.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 order-0 order-lg-1 text-center">
                            <img className="video_img wow fadeInRight" src={ipo1} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about_consulting_area bg-white">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-5">
                            <img className="video_img w-100 wow fadeInLeft" src={ipo2} alt={" "}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <h3>Mandate</h3>

                                <p>For its capabilities, contact network and
                                    institutional outreach,
                                    the company decided to bring in IRStrat as a key advisor in this process,
                                    contracting
                                    the IPO Program
                                    solution, with the mandate of easing the placement process, shorten the execution
                                    time,
                                    and maximize the
                                    valuation and size of the placement (full greenshoe or exercise of the whole
                                    over-allotment option).</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-5 pb-0">
                <div className="container ex_content">
                    <div className="row m-0">
                        <div className="col-md-12 pl-3 pl-lg-0">
                            <h3 className="s_title d-block">Solution</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="executive_area pt-3 pb-4">
                <div className="container">
                    <div className="row mt-3 step service_right_sidebar p-0 mb-5 d-flex align-items-center">
                        <div className="col-lg-7">
                            <div id="accordion7" role="tablist" aria-multiselectable="true"
                                 className="panel-group faq-accordion service_accordion">
                                <div className="card active">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion7" href="#collapse25"
                                               className="btn-accordion"
                                               aria-expanded="true" role="button" aria-controls="collapse25">
                                                <span className="pluse">+</span><span className="minus">-</span>Strategy
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse25" className="collapse show" aria-expanded="true" role="tabpanel">
                                        <div className="card-block panel-body"><p>IRStrat performs a preliminary market
                                            study and assesses
                                            the client's issuance scope in the local and international equity market, to
                                            elaborate a detailed
                                            action plan for achieving an optimal placement. Likewise, IRStrat prepares a
                                            work agenda detailing
                                            the precise steps to complete the filling of the prospectus, as well as
                                            carry out the roadshow and
                                            book building </p>
                                            <p>Our team, working with the senior management, begins to develop the
                                                company’s investment
                                                thesis, history and competitive advantage, as well as the communication
                                                style (qualitative and
                                                quantitative), and an optimal prospecting and communication
                                                strategy.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse26"
                                               className="btn-accordion collapsed" aria-expanded="false"
                                               aria-controls="collapse26">
                                                <span className="pluse">+</span><span className="minus">-</span>Tactic
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse26" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body"><p>IRStrat carries out the targeting and beauty
                                            contest of the
                                            underwriter syndicate, the targeting of institutional and retail investors,
                                            the national and
                                            international roadshow, and elaborates a proxy of the closed book. Likewise,
                                            an intensive lobbying
                                            is carried out with investment and pension funds, as well as presentations
                                            with family offices,
                                            endowments, sovereign funds, marketing and analysis departments of brokerage
                                            firms, treasuries,
                                            blue-chip mutual funds and specialized financial media.</p>
                                            <p>Additionally, IRStrat performs a thorough training of our client's
                                                executive team in investor
                                                education, roadshow presentations, face-to-face meetings and specialized
                                                forums. The Prospect
                                                and investor presentation are insightfully prepared, and a clear and
                                                impactful fact sheet
                                                containing the investment thesis and positive aspects of the instrument
                                                is developed.</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse27"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>Results
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse27" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">Our client issues an original amount of 1 billion
                                            dollars, plus 200
                                            million dollars from the exercise of the over-allotment option, with an IPO
                                            price 35% higher than
                                            the average valuation of its sector, and with an optimal participation of
                                            retail and institutional
                                            investors. The latter allowed the Company to maximize the liquidity of its
                                            shares, in parallel to
                                            developing an analyst coverage comprised of six institutions, which assigned
                                            investment
                                            recommendations of “strong buy”.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 text-center mt-4 mt-lg-0">
                            <img className="img-fluid w-100 wow fadeInRight" src={ipo3} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
